import React from 'react'
// import PropTypes from 'prop-types'
import Styled from 'styled-components'

import {graphql, Link} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image'

import Seo from '../../components/Seo'
import Main, {colors} from '../../styles/Styles'
import Header from '../../components/Header'
import Md from '../../components/Markdown'
import Footer from '../../components/Footer'

const Pic = Styled.div`

//using place-items:stretch instead
//width:calc((100vw - 11.5vw * 2 - 2vw * 3)/4);
//height:calc((100vw - 11.5vw * 2 - 2vw * 3)/4);

@media (orientation:landscape) {

${props => props.start && typeof props.start.x === "number" ? 'grid-column-start:'+props.start.x+';' : ''}
${props => props.start && typeof props.start.y === "number" ? 'grid-row-start:'   +props.start.y+';' : ''}
${props => props.gEnd  && typeof props.gEnd.x  === "number" ? 'grid-column-end:'  +props.gEnd.x+';' : ''}
${props => props.gEnd  && typeof props.gEnd.y  === "number" ? 'grid-row-end:'     +props.gEnd.y+';' : ''}

}

a, .container {
	display:block;
	position:relative;
	width:100%;
	height:100%;

	text-decoration:none!important;

	${props => typeof props.url === "string" && props.url.length ? 'cursor:pointer;' : ''};

	.overlay {
		${props => typeof props.text === "string" && props.text.length ? `

			width:100%;
			height:100%;
			position:absolute;
			top:0;
			bottom:0;
			left:0;
		right:0;

			
			h6 {
				position:absolute;
				bottom:2vw;
				left:2vw;
				
				opacity:0;
				color:${colors.white};
				margin:0;
				
				text-align:left;
				
			}
			
			background-color:rgba(0,0,0,0);
			
			&:hover{
				background-color:rgba(0,0,0,.8);
				
				h6{
					opacity:1;
				}
			}
		`:`
			display:none;
		`}
	}
}

`

function TeamMemberPage ({data: {strapiTeamMember : profile}}){
	
	const extra = !!profile.user ? {
		'profile:username' : profile.user.username,
		'profile:firstname':profile.user.firstname,
		'profile:lastname': profile.user.lastname,
	} : {'profile:username' : profile.Name}
	
	
	const ProPic = Array.isArray(profile.ProPic) ? profile.ProPic[profile.ProPic.length-1] : profile.ProPic
	
	return (
		<TeamMemberStyles>
			<Header/>
			
			<Seo title={profile.Name} description={"Profilo di "+profile.Name} type="profile" extra={extra}/>
			
			<h1>{profile.Name}</h1>
			{!!ProPic &&
			<GatsbyImage image={getImage(ProPic.childStrapiCloudinaryBridge)} alt={ProPic.alternativeText} className="proPic"/>
			}
			<section id="info">
				<div className="text">
					<Md>{profile.Bio.data.Bio}</Md>
				</div>
				
				<div className="skills">
					{profile.Skills.map(({Name, Level}, key) =>
					<div key={key} title={!Level ? '' : "livello: "+Level.toLowerCase()} className="skill">{Name}</div>
					)}
				</div>
			</section>
			
			<section id="gallery">
				{profile.gallery.map(({Url, Text, Photo, start, end}, key) =>
					<Pic key={key}  start={start} gEnd={end} text={Text} url={Url}>
						<Link to={Url}>
							<div className="container">
								<GatsbyImage image={getImage(Photo.childStrapiCloudinaryBridge)} alt={Photo.alternativeText}/>
								<div className="overlay">
									<h6>{Text}</h6>
								</div>
							</div>
						</Link>
					</Pic>
				)}
			</section>
			
			<Footer style={{marginTop:'300px'}}/>
			
		</TeamMemberStyles>
	)
}

const TeamMemberStyles = Styled(Main)`

text-align:center;
display:flex;
flex-direction:column;
align-items:center;

white-space:break-spaces;

h1 {
	margin:12.22vw 0 3.47vw;	//176

	@media (orientation:portrait) {
		margin-top:25vw;	//100
	}
}

.proPic {
	width:38vw;
	@media (orientation:portrait) {
		width:100%;
	}
}

#info {
	box-sizing:border-box;
	width:100%;
	padding:0 11.5vw;	//165
	margin:6.94vw 0 13.86vw;	//100 200
	
	display:flex;
	justify-content:space-between;
	
	@media (orientation:portrait) {
		flex-direction:column;
		padding:0;
	}

	.text {
		width:50%;
		white-space:break-spaces;
		text-align:left;

		p {
			margin:0;
		}

		@media (orientation:portrait) {
			width:100%;
		}
	}
	
	.skills {
		
		width:50%;
		padding-left:0px;
		
		display:flex;
		flex-wrap:wrap;
		gap: 10px;
		align-content: flex-end;
		
		@media (orientation:portrait) {
			width:100%;
			margin-top:7vw;
		}

		.skill {
			flex-grow:0;

			flex:0 0 auto;

			height:3.19vw;	//46

			display:flex;
			align-items:center;

			padding: .5vw 2.08vw;	//7 30
			box-sizing: border-box;
			border: .14vw solid ${() => colors.black};	//2
			border-radius: 3.47vw;	//50

			font-size: 1.53vw;	//22
			line-height: 2.22vw;	//32

			@media (orientation:portrait) {
				height:11.76vw;	//46

				padding: 1.79vw 5vw;	//7 30
				border-width:.51vw;	//2
				border-radius: 12.82vw;	//50

				font-size: 5.64vw;	//22
				line-height: 8.21vw;	//32


			}
		}

	}
}

#gallery {
	box-sizing:border-box;
	width:100%;
	padding:0 11.5vw;
	
	display:grid;

	//(largezza - padding*2 (dx e sx) - gap*3 ) / 4 colonne
	//NOTE: c'è un piccolo sforo in largezza, non capisco dove
	grid-template-columns: repeat(4, calc((100vw - 11.5vw * 2 - 2vw * 3)/4));
	grid-auto-rows:calc((100vw - 11.5vw * 2 - 2vw * 3)/4);
	
	place-items: stretch;
	justify-content: center;
	
	gap:2vw;

	@media (orientation: portrait) {
		padding:0 0 10vw 0;

		gap:3.85vw;

		grid-template-columns: repeat(2, calc((100vw - 3.85vw * 3)/2));
		grid-auto-rows:calc((100vw - 3.85vw * 3)/2);


		.overlay {
			display:none;

			//if we change our mind, this is a good start
			h6 {
				font-weight:400;
				font-size:4vw;
			}
		}

	}
}
`

export const query = graphql`
query($Url: String) {
	strapiTeamMember (Url: {eq: $Url}) {
		Name
		Bio {
			data {
				Bio
			}
		}
		user {
			firstname
			lastname
			username
		}
		ProPic {
			alternativeText
			childStrapiCloudinaryBridge {
				gatsbyImageData
			}
		}
		Skills {
			Name
			Level
		}
		gallery {
			Text
			Url
			Photo {
				alternativeText
				childStrapiCloudinaryBridge {
					gatsbyImageData
				}
			}
			start {
				X
				Y
			}
			end {
				X
				Y
			}
		}
	}
}
`

export default TeamMemberPage
